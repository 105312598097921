export function fillArray(length: number) {
  return Array.from({ length }, (_, i) => i + 1)
}

export function cleanObject(object: Types.PlainObject, deep = true, removeZero = false) {
  const obj: Types.PlainObject = {}
  for (const key in object) {
    if (typeof object[key] === 'object' && !Array.isArray(object[key])) {
      obj[key] = deep ? cleanObject(object[key], true, removeZero) : object[key]
    } else if (object[key]) {
      obj[key] = object[key]
    } else if (object[key] === 0 && !removeZero) {
      obj[key] = 0
    }
  }

  return obj
}

export function dynamicSort(property: string) {
  let sortOrder = 1
  if (property[0] === '-') {
    sortOrder = -1
    property = property.substr(1)
  }
  return function (a: any, b: any) {
    /* next line works with strings and numbers,
     * and you may want to customize it to your needs
     */
    const result = a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0
    return result * sortOrder
  }
}

export const sleep = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export function pad2num(value: number | string) {
  if (Number(value) < 10) {
    return `0${value}`
  }
  return value
}

export function deepCloneObject(inObject: Types.PlainObject) {
  if (typeof inObject !== 'object' || inObject === null) {
    return inObject // Return the value if inObject is not an object
  }

  // Create an array or object to hold the values
  const outObject = Array.isArray(inObject) ? [] : {}

  for (const key in inObject) {
    // @ts-ignore
    const value = inObject[key]

    // Recursively (deep) copy for nested objects, including arrays
    // @ts-ignore
    outObject[key] = deepCloneObject(value)
  }

  return outObject
}

export function deepCloneWithJSON(data: any) {
  return JSON.parse(JSON.stringify(data))
}

export const isEmpty = (obj: any) => JSON.stringify(obj) === '{}'

export const snakeToCapitalize = (str: string) => {
  const arrStr = str.split('_').map((item: any) => {
    item = item.toLocaleLowerCase()
    return item[0].toUpperCase() + item.slice(1)
  })
  return arrStr.join(' ')
}

export const slugToCapitalize = (str: string) => {
  const arrStr = str.split('-').map((item: any) => {
    item = item.toLocaleLowerCase()
    return item[0].toUpperCase() + item.slice(1)
  })
  return arrStr.join(' ')
}

export const uniqData = (arr: any[]) => {
  const distinct = (val: any, index: number, self: any) => self.indexOf(val) === index
  return arr.filter(distinct)
}

export const to17TrackLink = (trackingNumber: string) => {
  return `https://t.17track.net/en#nums=${trackingNumber}`
}

export function parsePhone(phone: string) {
  let digitPhone = phone.replace(/\D/g, '')
  if (digitPhone.length === 11 && digitPhone.startsWith('1')) {
    digitPhone = digitPhone.substring(1)
  }
  const x = digitPhone.match(/(\d{0,3})(\d{0,3})(\d{0,4})/)
  if (x !== null) {
    return (!x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '')).trim()
  }
  return ''
}

export function getQueryParams(params: Types.PlainObject): string {
  return (
    '?' +
    Object.keys(params)
      .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(params[key]))
      .join('&')
  )
}

export function getQueryParamsNoEncode(params: Types.PlainObject): string {
  return (
    '?' +
    Object.keys(params)
      .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(params[key]))
      .join('&')
  )
}
