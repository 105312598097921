// TODO: Return all card type as link to img
// TODO: Only check for first 4 digits => double check regex
import { shopHelpPath } from '@helpers/path'

export function getCardType(cardNumber: string, cardBrand?: string): string {
  // visa
  let re = new RegExp('^4')
  if (cardNumber.match(re) != null) {
    return shopHelpPath(`visa.svg`)
  }

  // Mastercard
  // Updated for Mastercard 2017 BINs expansion
  re = new RegExp(/^5[1-5][0-9]{2}/)
  if (re.test(cardNumber)) {
    return shopHelpPath(`master.svg`)
  }

  // AMEX
  re = new RegExp('^3[47]')
  if (cardNumber.match(re) != null) {
    return shopHelpPath(`amex.svg`)
  }

  // Discover
  re = new RegExp('^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)')
  if (cardNumber.match(re) != null) {
    return shopHelpPath(`discover.svg`)
  }

  // Diners
  re = new RegExp('^36')
  if (cardNumber.match(re) != null) {
    return shopHelpPath(`diners_club.svg`)
  }

  // Diners - Carte Blanche
  re = new RegExp('^30[0-5]')
  if (cardNumber.match(re) != null) {
    return shopHelpPath(`diners_club.svg`)
  }

  // JCB
  re = new RegExp('^35(2[89]|[3-8][0-9])')
  if (cardNumber.match(re) != null) {
    return shopHelpPath(`jcb.svg`)
  }

  // Visa Electron
  re = new RegExp('^(4026|417500|4508|4844|491(3|7))')
  if (cardNumber.match(re) != null) {
    return shopHelpPath(`visa.svg`)
  }

  return getPaymentCardBrandImg(cardBrand ?? '') ?? ''
}

// VISA = 'VISA',
// MASTER = 'MASTER',
// DISCOVER = 'DISCOVER',
// AMEX = 'AMEX',
// JCB = 'JCB',
// UNIONPAY = 'UNIONPAY',
// DINERS_CLUB = 'DINERS_CLUB'
const getPaymentCardBrandImg = (cardBrand = ''): string => {
  switch (cardBrand) {
    case 'diners':
      return shopHelpPath(`diners_club.svg`)
    case 'mastercard':
      return shopHelpPath('master.svg')
    default:
      return shopHelpPath(`${cardBrand?.toLocaleLowerCase()}.svg`)
  }
}
