import { getQueryParamsNoEncode } from '@helpers/utilities'
import getConfig from 'next/config'

export function getDeeplink(body?: Types.LoginModel) {
  return !body
    ? `${getConfig().publicRuntimeConfig.deepLink}`
    : `${getConfig().publicRuntimeConfig.deepLink}${getQueryParamsNoEncode(
        body
      )}&actionType=discussion`
}

export function isAndoirdIos(): boolean {
  return window.mobileCheck
  // return (
  //   navigator.platform.toLocaleLowerCase().indexOf('android') >= 0 ||
  //   navigator.platform.toLocaleLowerCase().indexOf('iphone') >= 0
  // )
}

export function isAndoird(): boolean {
  return navigator.platform.toLocaleLowerCase().indexOf('android') >= 0
}

export function isIos(): boolean {
  return navigator.platform.toLocaleLowerCase().indexOf('iphone') >= 0
}
