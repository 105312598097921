import { FC, useEffect } from 'react'
import { INotiStateItem, REMOVE } from './interface.d'

import css from 'styled-jsx/css'
import { useNotiContext } from './NotiContext'
import dompurify from 'dompurify'

const Notification: FC<INotiStateItem> = ({
  id,
  title = '',
  content = '',
  removable = true,
  timeout = 5,
  type = ''
}) => {
  const { notiDispatch } = useNotiContext()

  const remove = () => {
    notiDispatch({ type: REMOVE, payload: { id } })
  }

  useEffect(() => {
    if (timeout) {
      setTimeout(() => {
        remove()
      }, timeout * 1000)
    }
  }, [])

  return (
    <div className="noti animated slide-in-down">
      <style jsx global>
        {globalStyle}
      </style>
      <div className={`notification ${type}`}>
        {removable && <button className="delete" onClick={remove} />}
        {process.browser && (
          <>
            <h5 dangerouslySetInnerHTML={{ __html: dompurify.sanitize(title) }} />
            <p dangerouslySetInnerHTML={{ __html: dompurify.sanitize(content) }} />
          </>
        )}
      </div>
    </div>
  )
}

function Notifications({ notiList }: { notiList: INotiStateItem[] }) {
  return (
    <>
      {notiList.map((noti) => {
        return <Notification {...noti} key={noti.id} />
      })}
    </>
  )
}

const globalStyle = css.global`
  .noti {
    z-index: 1002;
    min-width: 200px;
    max-width: 450px;
    position: fixed;
    top: 64px;
    right: 32px;
    font-size: 13px;

    .notification {
      border-radius: 4px 8px 8px 4px;
      padding: 16px 48px 22px 30px;
      background-color: var(--indigo-10);

      h5 {
        font-size: 16px;
        line-height: 32px;
        font-weight: 600;
      }

      p {
        color: var(--white-100);
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        margin-top: 8px;
      }

      &.is-danger {
        border-left: 6px solid var(--danger);

        h5 {
          color: var(--danger);
        }
      }

      &.is-success {
        border-left: 6px solid var(--success-100);

        h5 {
          color: var(--success-100);
        }
      }

      &.is-info {
        border-left: 6px solid var(--secondary-100);

        h5 {
          color: var(--secondary-100);
        }
      }

      &.is-warning {
        border-left: 6px solid var(--warning-100);

        h5 {
          color: var(--warning-100);
        }
      }

      &.is-default {
        border-left: 6px solid var(--indigo-8);

        h5 {
          color: var(--indigo-8);
        }
      }

      .delete {
        min-width: 24px;
        min-height: 24px;
        top: 20px;
        right: 20px;
        background-color: transparent;

        &::before {
          background-color: var(--white-100);
        }

        &::after {
          background-color: var(--white-100);
        }
      }
    }

    @media screen and (max-width: 767px) {
      top: 12px;
      left: 12px;
      right: 12px;
      min-width: calc(100% - 24px);
    }
  }
`

export default Notifications
