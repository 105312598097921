export function getLocal(key: string) {
  return window.localStorage.getItem(key) || ''
}

export function setLocal(key: string, value: any) {
  return window.localStorage.setItem(key, value)
}

export function getLocalObject(key: string) {
  const value = getLocal(key)

  try {
    const obj = JSON.parse(value)
    return obj
  } catch (err) {
    return undefined
  }
}

export function setLocalObject(key: string, obj: any) {
  return setLocal(key, JSON.stringify(obj))
}
