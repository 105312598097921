import { getLocalObject, setLocalObject, setLocalOrderStatus } from '@/helpers'
import { createContext, useEffect, useReducer } from 'react'

export type OrderContextActions = 'REPLACE' | 'UPDATE_REVIEW'

export const LOCAL_ORDER = 'LOCAL_ORDER'
export const LOCAL_ORDER_STATUS = 'LOCAL_ORDER_STATUS'

// Reducer
const OrderDetailReducer = (
  state: Types.Order,
  action: { type: OrderContextActions; payload: any }
) => {
  let result = { ...state }
  switch (action.type) {
    case 'REPLACE':
      result = { ...action.payload }
      break
    case 'UPDATE_REVIEW':
      result = { ...state, review: { ...action.payload } }
      break
  }
  setLocalObject(LOCAL_ORDER, result)
  setLocalOrderStatus(result)
  return result
}

// Context
const OrderContext = createContext<{
  orderDetail: Types.Order
  dispatchOrder: React.Dispatch<any>
}>({
  orderDetail: {} as Types.Order,
  dispatchOrder: () => null
})

const OrderProvider: React.FC = ({ children }) => {
  const [orderDetail, dispatchOrder] = useReducer(OrderDetailReducer, {})

  useEffect(() => {
    const localOrder = (process.browser ? getLocalObject(LOCAL_ORDER) : {}) || {}
    dispatchOrder({ type: 'REPLACE', payload: localOrder })
  }, [])

  return (
    <OrderContext.Provider value={{ orderDetail, dispatchOrder: dispatchOrder }}>
      {children}
    </OrderContext.Provider>
  )
}

export { OrderContext, OrderProvider }
