import { createContext } from 'react'
import { Shop } from 'src/types/interfaces'

const initialShop: Shop = {
  config: {
    announcementBarText: 'string',
    bottomDescription: 'string',
    cellaId: 0,
    cellaName: 'string',
    cellaTitle: 'string',
    countdownTime: 788,
    defaultPixelIds: [],
    discountCode: 'string',
    discountPercent: 0,
    email: 'string',
    facebook: 'string',
    footerDescription: 'string',
    gaId: 'string',
    gaScript: 'string',
    hasCart: true,
    hasMenu: true,
    logo: 'string',
    middleDescription: 'string',
    mycellaDomain: 'string',
    numPurchased: 0,
    numVisitingPeople: 0,
    paypalClientId: 'string',
    phone: 'string',
    pixelContentId: 0,
    pixelId: 'string',
    shortDescription: 'string',
    shopId: 0,
    topDescription: 'string',
    variantDisplay: 'button'
  },
  meta: {
    description: 'string',
    favicon: 'string',
    image: 'string',
    title: 'string',
    url: 'string',
    googleVerificationId: 'string'
  },
  product: {
    description: 'string',
    images: [],
    properties: {},
    shortDescription: 'string',
    thumbnail: 0,
    title: 'string',
    variants: []
  },
  policies: [],
  reviews: [],
  style: {
    primaryColor: 'string',
    secondaryColor: 'string',
    textColor: 'string',
    backgroundColor: 'string',
    logoWidth: 'string'
  },
  isPreview: false,
  payment: {
    adapter: 'string',
    supportedCards: [],
    paypalClientId: 'string',
    payment_address: 'string',
    payment_phone: 'string',
    payment_email: 'string',
    enablePaypalExpress: true
  }
}

export const ShopContext = createContext<Shop>(initialShop)
export const ShopProvider = ShopContext.Provider

export default ShopContext
